import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.scss"

const Header = ({ siteTitle }) => (
  <header id="header" className="header">
    <div className="header-background header-main"></div>
    <div className="background-image noise"></div>
    <div className="container">
      <div className="header-layout">
        <div className="header-left">
          <nav className="header-navigation">
            <div className="nav-wrap">
              <a href="/services" className="nav-item">
                services
              </a>
            </div>
            <div className="nav-wrap">
              <a href="/technologies" className="nav-item">
                technologies
              </a>
            </div>
            <div className="nav-wrap">
              <a href="/discovery-phase" className="nav-item">
                approach
              </a>
            </div>
          </nav>
        </div>
        <div className="header-center">
          <a className="logo" href="/" aria-label="homepage logo link">
            <figure className="logo-name-wrap">
              <svg>
                <use className="logo-svg" href="#logo" />
              </svg>
            </figure>
          </a>
        </div>
        <div className="header-right">
          <nav className="header-navigation">
            <div className="nav-wrap">
              <a href="/case-studies" className="nav-item">
                cases
              </a>
            </div>
            <div className="nav-wrap">
              <a href="/blog" className="nav-item">
                blog
              </a>
            </div>
            <div className="nav-wrap">
              <a href="/about-us" className="nav-item">
                about us
              </a>
            </div>
            <div className="nav-wrap">
              <a href="/contact" className="nav-item">
                contact us
              </a>
            </div>
          </nav>
        </div>
        <a className="dropdown-toggle burger-menu burger-button" href="#menu">
          <span></span>
          <span></span>
          <span></span>
        </a>
        <ul className="social social-header">
          <li>
            <a
              className="social-link social-size"
              href="https://facebook.com/requestum/"
              target="_blank"
              rel="noopener"
              aria-label="facebook link"
            >
              <svg className="header-social-icon">
                <use xlinkHref="#facebook-logo" />
              </svg>
            </a>
          </li>
          <li>
            <a
              className="social-link social-size"
              href="https://www.upwork.com/agencies/~014e506c30e31fcaf0"
              target="_blank"
              rel="noopener"
              aria-label="upwork link"
            >
              <svg className="header-social-icon">
                <use href="#upwork-logo" />
              </svg>
            </a>
          </li>
          <li>
            <a
              className="social-link social-size"
              href="https://www.behance.net/requestum/"
              target="_blank"
              rel="noopener"
              aria-label="behance link"
            >
              <svg className="header-social-icon">
                <use href="#behance-logo" />
              </svg>
            </a>
          </li>
          <li>
            <a
              className="social-link social-size"
              href="https://www.linkedin.com/company/requestum/"
              target="_blank"
              rel="noopener"
              aria-label="linkedin link"
            >
              <svg className="header-social-icon">
                <use href="#linkedin-logo" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./footer.scss"
import LogoMedium from "../images/logo/logo-medium.svg"

const Footer = ({ siteTitle }) => (
  <div className={"section-reverse"}>
    <footer
      className="footer section-inter"
      h-style="--bg-image: none; --bg-color: #101015"
    >
      <div className="container">
        <div className="container-2">
          <div className="footer-layout">
            <div className="footer-left">
              <a className="logo" href="/">
                <figure className="logo-name-wrap">
                  <img className="img lazy" src={LogoMedium} alt="logo" />
                </figure>
              </a>
              <p>&copy; 2015 - 2020</p>
            </div>
            <div className="footer-center">
              <p className="col-12 col-6m">
                UA Sales Office{" "}
                <a href="mailto: sales@requestum.com">sales@requestum.com</a>
              </p>
              <p className="col-12 col-6m">
                HR Team{" "}
                <a href="mailto: talents@requestum.com">
                  talents@requestum.com
                </a>
              </p>
            </div>
            <div className="footer-right">
              <ul className="social social-contactus">
                <li>
                  <a
                    className="social-link social-size"
                    href="https://facebook.com/requestum/"
                    target="_blank"
                    rel="noopener"
                    aria-label="facebook link"
                  >
                    <svg className="footer-social-icon">
                      <use xlinkHref="#facebook-logo" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="social-link social-size"
                    href="https://www.behance.net/requestum/"
                    target="_blank"
                    rel="noopener"
                    aria-label="behance link"
                  >
                    <svg className="footer-social-icon">
                      <use xlinkHref="#behance-logo" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="social-link social-size"
                    href="https://www.linkedin.com/company/requestum/"
                    target="_blank"
                    rel="noopener"
                    aria-label="linkedin link"
                  >
                    <svg className="footer-social-icon">
                      <use xlinkHref="#linkedin-logo" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="social-link social-size"
                    href="https://www.upwork.com/agencies/~014e506c30e31fcaf0"
                    target="_blank"
                    rel="noopener"
                    aria-label="upwork link"
                  >
                    <svg className="footer-social-icon">
                      <use xlinkHref="#upwork-logo" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
